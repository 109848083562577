/* ==========================================================================
   2. General
   ========================================================================== */

   html,
   button,
   input,
   select,
   textarea {
     font-family: _font(primary);
   }
   
   html {
     box-sizing: border-box;
   
     @media only screen and (max-width: 600px) {
       border-width: 5px;
       font-size: 100%;
     }
   }
   
   body {
     color: _palette(primary);
     -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
     line-height: 1.66667;
     padding-left: 30px;
     padding-right: 30px;
   }
   
   a {
     color: _palette(accent);
     text-decoration: none;
     -webkit-transition: color .3s ease;
     transition: color .3s ease;
     &:hover {
       color: _palette(secondary);
       text-decoration: underline;
     }
   }
   
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
     color: _palette(secondary);
     font-family: _font(secondary);
     font-weight: 700;
     line-height: 1.2;
     margin: 2rem 0 1rem;
     text-rendering: optimizeLegibility;
     &:first-child {
       margin-top: 0;
     }
   }
   
   h1 {
     font-size: 2rem;
     line-height: 1.11111;
     letter-spacing: -1px;
   
     @media only screen and (max-width: 600px) {
       font-size: 1.875rem;
       letter-spacing: normal;
       line-height: 1.2;
     }
   }
   
   h2 {
     font-size: 1.66667rem;
     letter-spacing: -1px;
   
     @media only screen and (max-width: 600px) {
       font-size: 1.5rem;
       letter-spacing: normal;
     }
   }
   
   h3 {
     font-size: 1.33333rem;
   
     @media only screen and (max-width: 600px) {
       font-size: 1.25em;
     }
   }
   
   h4 {
     font-size: 1.11111rem;
   }
   
   h5 {
     font-size: 1rem;
   }
   
   h6 {
     font-size: 0.88889rem;
   }
   
   p {
     margin: 0 0 1.66667em;
   }
   
   address {
     font-style: italic;
     margin: 0 0 1.66667em;
   }
   
   mark {
     background: #fff7c5;
     padding: 0 3px;
   }
   
   ins {
     background: #fff7c5;
     padding: 0 3px;
     text-decoration: none;
   }
   
   pre {
     background: _palette(bg);
     border: 1px solid _palette(border);
     border-radius: 3px;
     font-family: Consolas, "Andale Mono", "Courier New", Courier, monospace;
     font-size: 0.88889rem;
     margin: 0 0 1.875em;
     overflow: auto;
     padding: 10px;
     white-space: pre;
   }
   
   :not(pre) > code {
     background: _palette(bg);
     border: 1px solid _palette(border);
     border-radius: 3px;
     font-family: Consolas, "Andale Mono", "Courier New", Courier, monospace;
     font-size: 0.88889rem;
     padding: 1px 3px;
     white-space: pre;
   }
   
   blockquote {
     border-left: 3px solid _palette(secondary);
     color: _palette(secondary);
     font-family: _font(secondary);
     font-style: italic;
     margin: 0 0 1.66667em -30px;
     padding: 0 0 0 30px;
   
     @media only screen and (max-width: 910px) {
       margin-left: 0;
       padding-left: 24px;
     }
   
     p {
       margin: 0 0 0.83333em;
     }
   
     small,
     cite {
       color: _palette(primary);
       display: inline-block;
       font-size: 0.88889em;
     }
   }
   
   dl {
     margin: 0;
   }
   
   dt {
     font-weight: bold;
   }
   
   dd {
     margin: 0 0 1.66667em;
   }
   
   ul,
   ol {
     margin: 0 0 1.66667em;
     padding: 0 0 0 24px;
   }
   
   li > ul,
   li > ol {
     margin-bottom: 0;
   }
   
   hr {
     background: _palette(border);
     border: 0;
     height: 1px;
     margin: 2.5em 0;
   }
   
   table {
     border-bottom: 1px solid _palette(border);
     line-height: 1.33333;
     margin: 0 0 1.66667em;
     text-align: left;
     width: 100%;
   }
   
   caption {
     font-size: 0.88889em;
     margin-bottom: 0.9375em;
     text-align: left;
   }
   
   th,
   td {
     border-top: 1px solid _palette(border);
     padding: 8px;
   }
   
   th {
      color: _palette(secondary);
      font-weight: bold;
   }
   
   table tbody > tr:nth-child(odd) {
     background: _palette(bg);
   }
   
   img {
     border-radius: 3px;
     height: auto;
     max-width: 100%;
   }
   
   embed,
   iframe,
   object,
   video {
     max-width: 100%;
   }
   
   .js-reframe,
   .image-card {
     margin: 0 0 1.66667em;
   }
   
   .image-card figcaption {
     color: _palette(meta);
     font-size: 0.77778rem;
     padding-top: 0.5em;
     text-align: center;
   }
   
   @media only screen and (min-width: 851px) {
     .width-full img,
     .width-wide img {
       margin-left: calc(425px - 50vw);
       margin-right: calc(425px - 50vw);
       max-width: none;
       width: calc(100vw - 90px);
     }
   }
   
   @media only screen and (min-width: 971px) {
     .width-wide img {
       margin-left: -60px;
       margin-right: -60px;
       width: 880px;
     }
   }
   
   /* Clearing floats */
   .inner:after,
   .site-header:after,
   .site-footer:after {
     clear: both;
   }
   
   .inner:before,
   .inner:after,
   .post-content:before,
   .post-content:after {
     content: "";
     display: table;
   }
   
   /* Text meant only for screen readers */
   .screen-reader-text {
     border: 0 none;
     clip: rect(1px, 1px, 1px, 1px);
     height: 1px;
     overflow: hidden;
     padding: 0;
     position: absolute !important;
     width: 1px;
   }
   
   /* Text selection */
   ::-moz-selection {
     background: #e4edfa;
     color: _palette(secondary);
     text-shadow: none;
   }
   
   ::selection {
     background: #e4edfa;
     color: _palette(secondary);
     text-shadow: none;
   }
   
   /* Buttons */
   #menu-open,
   #menu-close,
   .back-to-top {
     background: 0;
     border: 1px solid _palette(border);
     border-radius: 3px;
     box-sizing: border-box;
     color: _palette(primary);
     padding: 0;
     position: relative;
     height: 36px;
     width: 36px;
     -webkit-transition: border-color .3s, color .3s;
     transition: border-color .3s, color .3s;
   
     &:hover,
     &:focus,
     &:active {
       border-color: _palette(accent);
       color: _palette(accent);
       outline: 0;
     }
   }
   
   /* Custom icons */
   .icon-menu {
     background: currentColor;
     color: inherit;
     height: 2px;
     left: 50%;
     margin-left: -11px;
     margin-top: -1px;
     position: absolute;
     top: 50%;
     width: 22px;
   
     &:before,
     &:after {
       background: currentColor;
       content: "";
       height: 100%;
       left: 0;
       position: absolute;
       width: 100%;
     }
   
     &:before {
       top: -5px;
     }
   
     &:after {
       bottom: -5px;
     }
   }
   
   .icon-close {
     background: 0;
     color: inherit;
     height: 2px;
     left: 50%;
     margin-left: -12px;
     margin-top: -1px;
     position: absolute;
     top: 50%;
     width: 24px;
   
     &:before,
     &:after {
       background: currentColor;
       content: "";
       height: 100%;
       left: 0;
       position: absolute;
       width: 100%;
     }
   
     &:before {
       top: 0;
       -webkit-transform: rotate(45deg);
       transform: rotate(45deg);
     }
   
     &:after {
       top: 0;
       -webkit-transform: rotate(-45deg);
       transform: rotate(-45deg);
     }
   }